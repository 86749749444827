// nuxt-ui/middleware/notFoundKings.ts
import { useWebContext } from "~/nuxt-ui/Shared/composables/webContext";

export default defineNuxtRouteMiddleware(() => {
  const { isKings } = useWebContext();

  if (isKings) {
    throw createError({ statusCode: 404 });
  }
});
